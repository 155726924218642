import React, { useState } from "react"
import headerStyles from '../../style/header.module.scss'
import styled from "styled-components"
import Navigation from "./navigation"


const BurgerSlice = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
z-index: 100;



div{
    transition: transform 300ms;
    background-color: ${({ nav, isWhite }) => nav || isWhite ? "black" : "white"};


    :nth-child(1){
        transform: ${({ nav }) => nav ? "rotate(45deg)" : "rotate(0)"};
        width: ${({ nav }) => nav ? "130%" : "100%"};
    }
    :nth-child(2){
        opacity: ${({ nav }) => nav ? "0" : "1"};
        transition: none;
    }
    :nth-child(3){
        transform: ${({ nav }) => nav ? "rotate(-45deg)" : "rotate(0)"};
        width: ${({ nav }) => nav ? "130%" : "100%"};
    }
}

`
const Slider = styled.div`
    transform: ${({ nav }) => nav ? "translateX(0)" : "translateX(100%)"};
`



const MobileMenu = ({ isWhite }) => {

    const [showNav, setShowNav] = useState(false)


    return (
        <div className={headerStyles.mobilemenu}>
            <button onClick={() => setShowNav(!showNav)} className={headerStyles.burgerbutton}>
                <BurgerSlice nav={showNav} isWhite={isWhite}>
                    <div className={headerStyles.burgerslice} />
                    <div className={headerStyles.burgerslice} />
                    <div className={headerStyles.burgerslice} />
                </BurgerSlice>
            </button>
            <Slider nav={showNav} className={headerStyles.mobilemenuSlider}>
          <nav>
           <Navigation isWhite={isWhite}/>
           </nav>
            </Slider>
        </div>
    )


}

export default MobileMenu
