import { FormattedMessage } from 'gatsby-plugin-intl'
import React from "react"
import headerStyles from '../../style/header.module.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import LanguageSwitch from "./language.switch.js"
import Chevron from "../../img/chevron.svg"


const Navigation = ({isWhite}) => {


  function getSubItems(subItems) {
    const elementArray = []
    subItems.forEach((subItem, index) => {
      elementArray.push(<li key={`${index}-${subItem.text}`}><AniLink cover bg="#F2F2F2" duration={1} direction="right" partiallyActive={true} activeClassName={headerStyles.active} to={subItem.url}>{subItem.text}</AniLink></li>)
    })
    return elementArray
  }

const menuItems = [
    {
      text: <FormattedMessage id="Nav.services" />,
      subItems: [
        {
          url: "../services",
          text: <FormattedMessage id="Nav.services.software" />
        },
        {
          url: "../service_digitalsignage",
          text: <FormattedMessage id="Nav.services.digitalsignage" />,
        }
      ]
    },
    {
      url: "../projects",
      text: <FormattedMessage id="Nav.projects" />
    },
    {
      text: <FormattedMessage id="Nav.agency" />,
      subItems: [
        {
          url: "../jobs",
          text: <FormattedMessage id="Nav.jobs" />,
        }
      ]
    },
    {
      url: "../contact",
      text: <FormattedMessage id="Nav.contact" />
    },

  ]



  return (
        <ul>
          {
            menuItems.map((item, index) => {
              if(item.subItems) {
               return <div  key={`${index}-${item.text}`} className={headerStyles.subMenuItem}>
                 <span>
                  <img src = {Chevron} alt=">" style={isWhite ? { filter: "" } : { filter: "brightness(700%)" }}/>
               {item.text} </span>
                <ul>
               {
                  getSubItems(item.subItems)
                }
                </ul>
                </div>
              }
              else {
                return <li key={item.url}><AniLink cover bg="#F2F2F2" duration={1} direction="right" partiallyActive={true} activeClassName={headerStyles.active} to={item.url}>{item.text}</AniLink></li>
              }
            })
          }
          <li><LanguageSwitch /></li>
        </ul>
  )
}

export default Navigation



