import React, { useState, useEffect } from "react"
import Logo from '../../img/logo_poppins.png'
import headerStyles from '../../style/header.module.scss'
import MobileMenu from './mobilemenu.js'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import DesktopMenu from "./desktopmenu"




const HeaderContainer = styled.header`
background: ${({ headerBg }) => headerBg ? "rgba(255, 255, 255, .95)" : "transparent"};
color: ${({ headerBg }) => headerBg ? "#2E2D2D" : "white"};

a{
  color: ${({ headerBg }) => headerBg ? "#2E2D2D" : "white"};
}
`

const Header = () => {

  const [headerBg, setHeaderbg] = useState(0)


  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  function handleScroll() {
    if (window.scrollY <= 0) {
      setHeaderbg(false)
    } else {
      setHeaderbg(true)
    }
  }


  return (
    <HeaderContainer headerBg={headerBg}>
    <div>
        <AniLink className={headerStyles.logo} cover bg="#F2F2F2" duration={1} direction="right" to="../"><img style={headerBg ? { filter: "" } : { filter: "brightness(700%)" }} src={Logo} alt="Spherical Elephant Logo"></img></AniLink>
        <DesktopMenu isWhite={headerBg}/>
        <MobileMenu isWhite={headerBg} />
    </div>
    </HeaderContainer>
  )
}

export default Header
