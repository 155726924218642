import React from "react"
import headerStyles from '../../style/header.module.scss'
import Navigation from "./navigation"


const DesktopMenu = ({isWhite}) => {

  return (
      <nav className={headerStyles.desktopMenu}>
        <Navigation isWhite={isWhite}/>
    </nav>
  )
}

export default DesktopMenu
