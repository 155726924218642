import React, { useState, useEffect } from "react"
import footerStyles from '../../style/footer.module.scss'
import styled from "styled-components"
import PlauderButton from "./plauderbtn.js"
import { FormattedMessage } from 'gatsby-plugin-intl'
import AniLink from "gatsby-plugin-transition-link/AniLink"


const FooterDiv = styled.div`
transform: ${({ revealFooter }) => revealFooter ? "tranlsateY(0%)" : "translateY(100%)"};
position: relative;
bottom: 0;
transition: all ease 300ms;
z-index: 5;


`


const Footer = () => {

  const [revealFooter, setShowFooter] = useState(0)


  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  function handleScroll() {

    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight * 0.95) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }


  return (
    <FooterDiv revealFooter={revealFooter} className={footerStyles.wrapper}>
      <section className={footerStyles.contactSection}>
        <div>

          <div>
            <h4><FormattedMessage id="Footer.h" /></h4>

            <FormattedMessage id="Footer.p" />
          </div>
          <PlauderButton mode="dark"></PlauderButton>
        </div>


      </section>
      <nav style={{ height: "3rem", display: "flex", alignItems: "center" }}>
        <ul>
          <li>
            <AniLink cover bg="#F2F2F2" duration={1} direction="right" partiallyActive={true} to="../contact"><FormattedMessage id="Nav.contact" /></AniLink>
          </li>
          <li>
            <AniLink cover bg="#F2F2F2" duration={1} direction="right" partiallyActive={true} to="../imprint"><FormattedMessage id="Nav.imprint" /></AniLink>
          </li>
          <li>
            <AniLink cover bg="#F2F2F2" duration={1} direction="right" partiallyActive={true} to="../privacy"><FormattedMessage id="Nav.privacy" /></AniLink>
          </li>
        </ul>
      </nav>
    </FooterDiv>
  )
}

export default Footer
