import React from "react"
import { useIntl } from 'gatsby-plugin-intl'

const Image = (props) => {

    const intl = useIntl()


    const altTag = <p>{intl.formatMessage({id: props.alt})}</p>
    const altText = altTag.props.children


    return (
        <img src={props.src} alt={altText} className = {props.className}>
        </img>
    )
}

export default Image

