import React, { useState, useEffect, useRef } from "react"
import cookieStyles from "../../style/cookie.module.scss"
import cookieImg from "../../img/cookie.svg"
import { FormattedMessage } from 'gatsby-plugin-intl'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../global/image.js"

const CookieBanner = (props) => {


    const [accepted, setAccepted] = useState(true)
    const bannerRef = useRef(0)


    useEffect(() => {
        window.addEventListener("onload",getCookie())
        window.addEventListener("onload",setPageVisibility())
        return() =>{
            window.removeEventListener("onload", getCookie())
            window.removeEventListener("onload",setPageVisibility())
        }
    })

    function setPageVisibility(){
        if(props.hide){
            setAccepted(true) 
        }
    }

    function setCookie() {
        hideBanner()
        setAccepted(true)
        let d = new Date();
        d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "accepted=true;" + expires + ";path=/";
    }

    function getCookie() {
        const acceptedName = "accepted=true"
        let cookieString = decodeURIComponent(document.cookie)
        var cookieSplit = cookieString.split(';');
        var acceptedIndex = cookieSplit.indexOf(acceptedName)
        if (acceptedIndex === -1) {
            setAccepted(false)
        }
        else {
            setAccepted(true)
        }
    }

    function hideCookie(){
        return accepted ? {display: "none"} : {display: "block"}
    }

    function hideBanner(){
        bannerRef.current.style = "display: none"
    }

    return (

        <div ref = {bannerRef} style = {hideCookie()} className={cookieStyles.cookieWrapper}>
            <Image src={cookieImg} alt="Cookie.alt" />
            <p className={cookieStyles.firstP}><FormattedMessage id="Cookie.p1" /> </p>
            <p className={cookieStyles.secondP}><FormattedMessage id="Cookie.p2" /> </p>
            <button onClick={() => setCookie()}><FormattedMessage id="Cookie.button" /> </button>
            <AniLink className={cookieStyles.link} cover bg="#F2F2F2" duration = {1} direction="right" to = "../privacy">
            <FormattedMessage id="Nav.privacy" /> </AniLink>
        </div>

    )
}


export default CookieBanner
