import React, {useEffect, useState} from "react"
import headerStyles from '../../style/header.module.scss'
import { changeLocale, useIntl } from 'gatsby-plugin-intl'



const LanguageSwitch = () => {

    const intl = useIntl();
    const [activeLanguage, setActiveLanguage] = useState("de")

    useEffect(() => {
        setActiveLanguage(intl.locale)
        }, [intl.locale])
        
    function getPosition(){
        return activeLanguage === "de" ? {transform: "translate(75%, -50%)"} : {transform: "translate(-70%, -50%)"}
    }

    function changeLanguage(){
        if(activeLanguage === "en"){
            setActiveLanguage("de")
            changeLocale("de")

        } else {
            setActiveLanguage("en")
            changeLocale("en")
        }
    }

    function getActiveClass(lang){
        if(lang === activeLanguage){
            return headerStyles.activeLang
        } else {
            return headerStyles.inactiveLang
        }

    }

    return (
        <button onClick = {() => changeLanguage()} className = {headerStyles.languageSwitchContainer}>
            <div style = {getPosition()} className = {headerStyles.toggleCircle}/>
            <div className = {getActiveClass("de")}>DE</div>
            <div className = {getActiveClass("en")}>EN</div>
        </button>

    )
}

export default LanguageSwitch
